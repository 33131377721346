<template>
	<div class="formulations">
		<form class="form">
			<div v-if="!$vuetify.breakpoint.mobile" class="form-search-actions">
				<button type="button" class="form-btn d-block" @click="submit()">Search</button>
				<button type="button" class="form-btn mt-2" @click="clear()">Clear</button>
			</div>

			<div class="form-group">
				<label>Search All Fields</label>

				<div class="form-group-input">
					<input
						type="text"
						size="50"
						maxlength="50"
						class="text"
						v-model="searchForm.keywords"
						@keyup.enter="submit()"
						style="font-size: 16px"
					/>
				</div>
			</div>

			<div class="form-group">
				<label>Formulation Name</label>

				<div class="form-group-input">
					<input
						type="text"
						size="50"
						maxlength="50"
						class="text"
						v-model="searchForm.formulation"
						@keyup="delay('liveSearch', 'formulation', 400)"
						@keyup.enter="submit()"
						@click="setSearchElement"
						style="font-size: 16px"
					/>
					<button
						type="button"
						class="form-group-input-clear"
						:style="!searchForm.formulation.length ? 'opacity: 0;' : null"
						@click="
							searchForm.formulation = '';
							formulationResults = [];
						"
					>
						X
					</button>

					<div
						v-if="(formulationResults.length > 0) & (windowY <= 120)"
						class="form-group-input-results"
					>
						<span
							v-for="(text, i) in formulationResults"
							:key="i"
							class="form-group-input-results-item"
							@mousedown="
								text !== 'No Matches Found'
									? ((searchForm.formulation = text),
									  (searchForm.isWildcardSearch = false),
									  (formulationResults = []))
									: null
							"
							v-html="highlightText('formulation', text)"
						></span>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>Vendor</label>

				<div class="form-group-input">
					<input
						type="text"
						size="50"
						maxlength="50"
						class="text"
						v-model="searchForm.vendor"
						@keyup="delay('liveSearch', 'vendor', 400)"
						@keyup.enter="submit()"
						@click="setSearchElement"
						style="font-size: 16px"
					/>
					<button
						type="button"
						class="form-group-input-clear"
						:style="!searchForm.vendor.length ? 'opacity: 0;' : null"
						@click="
							searchForm.vendor = '';
							vendorResults = [];
						"
					>
						X
					</button>

					<div v-if="vendorResults.length > 0" class="form-group-input-results">
						<span
							v-for="(text, i) in vendorResults"
							:key="i"
							class="form-group-input-results-item"
							@mousedown="
								text !== 'No Matches Found'
									? ((searchForm.vendor = text),
									  (searchForm.isWildcardSearch = false),
									  (vendorResults = []))
									: null
							"
							v-html="highlightText('vendor', text)"
						></span>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>Formulation Use</label>

				<div class="form-group-input">
					<input
						type="text"
						size="50"
						maxlength="50"
						class="text"
						v-model="searchForm.formulationUse"
						@keyup="delay('liveSearch', 'formulationUse', 400)"
						@keyup.enter="submit()"
						@click="setSearchElement"
						style="font-size: 16px"
					/>
					<button
						type="button"
						class="form-group-input-clear"
						:style="!searchForm.formulationUse.length ? 'opacity: 0;' : null"
						@click="
							searchForm.formulationUse = '';
							formulationUseResults = [];
						"
					>
						X
					</button>

					<div v-if="formulationUseResults.length > 0" class="form-group-input-results">
						<span
							v-for="(text, i) in formulationUseResults"
							:key="i"
							class="form-group-input-results-item"
							@mousedown="
								text !== 'No Matches Found'
									? ((searchForm.formulationUse = text),
									  (searchForm.isWildcardSearch = false),
									  (formulationUseResults = []))
									: null
							"
							v-html="highlightText('formulationUse', text)"
						></span>
					</div>
				</div>
			</div>

			<div v-if="$vuetify.breakpoint.mobile" class="mobile-form-search-actions">
				<button type="button" class="form-btn d-block" @click="submit()">Search</button>
				<button type="button" class="form-btn mt-2" @click="clear()">Clear</button>
			</div>
		</form>

		<div v-if="resultsTable.length > 0 || resultsError" class="formulations-results">
			<table
				@click.right.prevent
				v-if="!resultsError"
				cellspacing="0"
				class="formulations-results-table rslt"
			>
				<thead>
					<tr>
						<th width="40%">
							<a class="sortable" id="srt_pname" @click="sortTable('formulationName')">
								Formulation Name
								<v-icon v-show="formulationFilter">mdi-triangle</v-icon>
							</a>
						</th>
						<th width="60%">
							<a class="sortable" id="srt_vendor" @click="sortTable('vendorName')">
								Vendor
								<v-icon v-show="vendorNameFilter">mdi-triangle</v-icon>
							</a>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(result, i) in resultsTable">
						<template>
							<tr
								:key="i"
								:class="[i % 2 == 0 ? 'row-even' : 'row-odd', result.isPartner ? 'promote' : '']"
							>
								<td class="c1">
									<a
										class="product-dialog"
										target="_blank"
										:href="
											getDocumentLink(result.formulationLink, result.formulationDropboxId)
										"
										@click="
											selectedResult = result;
											logActivity(
												'FormulationLinkClicked',
												JSON.stringify({
													description: result.formulationName,
													formulation: getDocumentLink(
														result.formulationLink,
														result.formulationDropboxId
													),
												})
											);
										"
									>
										{{ result.formulationName }}
									</a>
								</td>
								<td class="c2">{{ result.vendorName }}</td>
							</tr>
							<tr :class="[i % 2 == 0 ? 'row-even' : 'row-odd']" :key="`${i}-details`"></tr>
						</template>
					</template>
				</tbody>
			</table>
			<div v-else id="noResults" style="display: inline-block">
				<p>No formulations match the specified search criteria.</p>
				<p>
					<router-link to="/internet-search">
						Click here to use the ChemFormation Custom Internet Search
					</router-link>
				</p>
			</div>
		</div>
		<div v-if="resultsTable.length > 0 || resultsError" style="height: 200px"></div>
	</div>
</template>

<script>
export default {
	name: "FormulationsSearch",
	data() {
		return {
			searchForm: {
				keywords: "",
				formulation: "",
				vendor: "",
				formulationUse: "",
				isWildcardSearch: true,
			},
			formError: false,
			searchElement: null,
			resultsTable: [],
			selectedResult: {},
			formulationResults: [],
			vendorResults: [],
			formulationUseResults: [],
			loadingResults: false,
			resultsError: false,
			filterSelected: false,
			formulationFilter: false,
			vendorNameFilter: false,
			timer: null,
			windowY: 0,
		};
	},
	methods: {
		async submit() {
			this.resultsError = false;
			this.loadingResults = true;

			//clear out live results to hide them on search and unblur the input
			event.target.blur();
			this.formulationResults = [];
			this.vendorResults = [];
			this.formulationUseResults = [];

			try {
				let searchQuery = {
					searchTerm: this.searchForm.keywords,
					formulation: this.searchForm.formulation,
					vendor: this.searchForm.vendor,
					use: this.searchForm.formulationUse,
					isWildcardSearch: this.searchForm.isWildcardSearch,
				};

				const res = await this.$http.post(`/api/search/formulation`, searchQuery);
				console.log(res);

				await this.logActivity("FormulationSearchExecuted", searchQuery);

				this.resultsTable = res.data.searchResults.formulations;
				this.loadingResults = false;

				//scroll to table
				this.$nextTick(() => {
					const tableEl = document.querySelector(".formulations-results-table");
					const y = tableEl.getBoundingClientRect().top + window.pageYOffset - 40;
					window.scrollTo({ top: y, behavior: "smooth" });
				});
			} catch (error) {
				console.log(error.response);
				if (error.response.status == 404) {
					this.resultsError = true;
					this.loadingResults = false;
					//scroll to error table
					this.$nextTick(() => {
						const errorResult = document.querySelector(".formulations-results");
						const y = errorResult.getBoundingClientRect().top + window.pageYOffset;
						window.scrollTo({ top: y, behavior: "smooth" });
					});
				}
			}
		},

		setSearchElement(event) {
			if (this.searchElement == event.target) {
				return;
			} else {
				this.searchElement = event.target;

				//reset live search results if search element changes
				this.formulationResults = [];
				this.vendorResults = [];
				this.formulationUseResults = [];
			}
		},

		hideLiveSearch(event) {
			//if any of the live searchs have results hide and clear all results if user clicks away from the results
			if (
				this.formulationResults.length > 0 ||
				this.vendorResults.length > 0 ||
				this.formulationUseResults.length > 0
			) {
				if (
					event.target.classList.contains("form-group-input-results") ||
					event.target.classList.contains("form-group-input-results-item") ||
					event.target == this.searchElement
				) {
					return;
				} else {
					this.formulationResults = [];
					this.vendorResults = [];
					this.formulationUseResults = [];
				}
			}
		},

		highlightText(type, str) {
			if (str == "No Matches Found") return str;

			if (type == "formulation") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.formulation, str, " ");
				return htmlString;
			} else if (type == "vendor") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.vendor, str, " ");
				return htmlString;
			} else if (type == "formulationUse") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.formulationUse, str, " ");
				return htmlString;
			}
		},

		getDocumentLink(fileLink, dropboxId) {
			if (fileLink) {
				let isFilePath = fileLink.startsWith("/");
				let parts = fileLink.split(".");
				let extension = parts.pop();
				let isPdf = extension.includes("pdf");
				return isFilePath
					? `/api/storage/${isPdf ? "preview" : "download"}-file?filePath=` +
							encodeURIComponent(fileLink) +
							"&dropboxId=" +
							dropboxId
					: fileLink;
			}
		},

		divide_and_conquer_replace(query, option, separator) {
			let terms, terms_esc;

			//The inner replacement function
			function divide_and_conquer_inner(bites, depth) {
				let this_term, i, bite, match, new_bites, found_all_others;

				depth = depth ? depth : 1;

				//Get the longest remaining term
				this_term = terms_esc[terms_esc.length - depth];

				//Loop all the bites
				for (i = 0; i < bites.length; i++) {
					bite = bites[i];

					//Reset the lastIndex since we're reusing the RegExp objects
					this_term.lastIndex = 0;

					//Check that we have a string (ie. do not attempt to match bites
					//that are already consumed)
					if (typeof bite === "string") {
						//Find the next matching position (if any)
						while ((match = this_term.exec(bite)) !== null) {
							new_bites = i > 0 ? bites.slice(0, i) : [];
							if (match.index > 0) {
								new_bites.push(bite.slice(0, match.index));
							}
							new_bites.push(["<b>" + match[0] + "</b>"]);
							if (this_term.lastIndex < bite.length) {
								new_bites.push(bite.slice(this_term.lastIndex));
							}
							if (i < bites.length - 1) {
								new_bites = new_bites.concat(bites.slice(i + 1));
							}

							if (terms_esc.length > depth) {
								//Attempt to find all other terms
								found_all_others = divide_and_conquer_inner(new_bites, depth + 1);

								//If we found all terms we'll pass the modified string all the
								//way up to the original callee
								if (found_all_others) {
									return found_all_others;
								}
								//Otherwise try to match current term somewhere else
								this_term.lastIndex = match.index + 1;
							} else {
								//If no terms remain we have a match
								return new_bites.join("");
							}
						}
					}
				}
				//If we reach this point at least one term was not found
				return null;
			}

			// Split query in terms at delimiter
			terms = query.split(separator).filter(Boolean);
			if (!terms.length) return option;

			//Sort terms according to length - longest term last
			terms.sort((a, b) => a.length - b.length);

			//Escape terms and store RegExp's instead of strings
			terms_esc = terms
				.map((term) => term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))
				.map((term) => new RegExp(term, "gi"));

			return divide_and_conquer_inner([option]);
		},

		delay(func, type, time) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this[func](type);
			}, time);
		},

		sortTable(key) {
			//sort alphanumerically by specified key
			const alphaNumericSort = (arr = [], key) => {
				const sorter = (a, b) => {
					const isNumber = (v) => (+v).toString() === v;
					const aPart = a[key].match(/\d+|\D+/g);
					const bPart = b[key].match(/\d+|\D+/g);
					let i = 0;
					let len = Math.min(aPart.length, bPart.length);
					while (i < len && aPart[i] === bPart[i]) {
						i++;
					}
					if (i === len) {
						return aPart.length - bPart.length;
					}

					if (isNumber(aPart[i]) && isNumber(bPart[i])) {
						return aPart[i] - bPart[i];
					}

					return aPart[i].localeCompare(bPart[i]);
				};

				const sortedArr = JSON.parse(JSON.stringify(arr)).sort(sorter);
				return sortedArr;
			};

			//return reversed results if sortedResults match otherwise return the sortedResults
			let sortedResults = alphaNumericSort(this.resultsTable, key);
			if (sortedResults[0].productId === this.resultsTable[0].productId) {
				this.resultsTable = sortedResults.reverse();
				event.target.querySelector("i").classList.add("rotate");
			} else {
				this.resultsTable = sortedResults;
				event.target.querySelector("i").classList.remove("rotate");
			}
		},

		async liveSearch(type) {
			//cancel live search if currentlly loading results
			if (this.loadingResults) {
				this.formulationResults = [];
				this.vendorResults = [];
				this.formulationUseResults = [];
				return;
			}
			//trigger isWildCardSearch
			this.searchForm.isWildcardSearch = true;

			try {
				if (type == "formulation") {
					if (this.searchForm.formulation == "") {
						this.formulationResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/formulation`, {
							params: { searchTerm: this.searchForm.formulation },
						});
						this.formulationResults = res.data;
					}
				} else if (type == "vendor") {
					if (this.searchForm.vendor == "") {
						this.vendorResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/formulation-vendor`, {
							params: { searchTerm: this.searchForm.vendor },
						});
						this.vendorResults = res.data;
					}
				} else if (type == "formulationUse") {
					if (this.searchForm.formulationUse == "") {
						this.formulationUseResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/formulation-use`, {
							params: { searchTerm: this.searchForm.formulationUse },
						});
						this.formulationUseResults = res.data;
					}
				}

				if (this.loadingResults) {
					this.formulationResults = [];
					this.vendorResults = [];
					this.formulationUseResults = [];
				}
			} catch (error) {
				if (error.response.status == 404) {
					if (type == "formulation") {
						this.formulationResults = ["No Matches Found"];
					} else if (type == "vendor") {
						this.vendorResults = ["No Matches Found"];
					} else if (type == "formulationUse") {
						this.formulationUseResults = ["No Matches Found"];
					}
				}
				console.log(error);
			}
		},

		clear() {
			window.scrollTo({ top: 0 });

			this.searchForm = {
				keywords: "",
				formulation: "",
				vendor: "",
				formulationUse: "",
			};

			this.formError = false;
			this.searchElement = null;
			this.resultsTable = [];
			this.selectedResult = {};
			this.formulationResults = [];
			this.vendorResults = [];
			this.formulationUseResults = [];
			this.loadingResults = false;
			this.resultsError = false;
			this.filterSelected = false;
			this.formulationFilter = false;
			this.vendorNameFilter = false;
			this.timer = null;
			this.windowY = 0;
		},

		async logActivity(event, context) {
			let searchContext;

			// If context is already a string, use it as is
			if (typeof context === "string") {
				searchContext = context;
			}
			// If context is an object, stringify it
			else if (typeof context === "object" && context !== null) {
				searchContext = JSON.stringify(context);
			}
			// If context is neither a string nor an object, stringify an empty object
			else {
				searchContext = JSON.stringify({});
			}

			let payload = {
				productId: null,
				area: "Formulations",
				event: event,
				searchContext: searchContext,
			};

			try {
				await this.$http.post(`/api/search/log-activity`, payload);
			} catch (error) {
				console.error("Error logging search activity:", error);
			}
		},
	},
	created() {
		window.addEventListener("scroll", this.watchScroll);

		//add click listener to handle showing/hiding live search box
		window.addEventListener("click", this.hideLiveSearch);
	},
	destroyed() {
		window.removeEventListener("scroll", this.watchScroll);
		window.removeEventListener("click", this.hideLiveSearch);
	},
};
</script>

<style lang="scss" scoped>
.rotate {
	transform: rotate(180deg);
}

.formulations {
	position: relative;

	&-results {
		position: relative;
		display: block;

		// Prevent Copy-Paste
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;

		&-table {
			width: 100%;
			color: $color-black;
			border-radius: 5px;

			thead {
				th {
					.sortable {
						position: relative;
						color: $color-black;
						padding-right: 1rem;

						i {
							position: absolute;
							right: 0;
							top: 3px;
							font-size: 0.8rem;
						}
					}

					.action {
						color: #003399;
						text-decoration: underline;
					}
				}
			}

			tbody {
				td {
					&.rslt-alt {
						ul {
							list-style: none;
							padding-left: 0;
							line-height: 1.2;
						}
					}

					a {
						color: #003399;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.fade-enter-active {
	animation: drop 0.5s forwards;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-leave-to {
	opacity: 0;
}
@keyframes drop {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}
</style>
